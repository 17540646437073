import React from 'react';
import { graphql } from 'gatsby';
import Page from '../components/Page';
import Post from '../components/Post';
import Heading from '../components/Heading';

function BlogPostTemplate({ data }) {
  const post = data.markdownRemark;

  return (
    <Page title={post.frontmatter.title}>
      <Heading title={post.frontmatter.date} />
      <Post title={post.frontmatter.title} html={post.html} />
    </Page>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
